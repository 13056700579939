<template>
  <jrv-pick-streamlined
    v-slot="{ jrv, updateSelect }"
    title="Cierre de JRVs"
    nav1="JRV"
    noJrv="Escoja una JRV del desplegable superior para marcarla como cerrada."
    :nombreJrv="nombreJrvDecorados"
  >
    <div v-if="jrv">
      <div class="banner nivel2" v-if="cierreJRV[jrv]">
        La {{ nombreJrv[jrv] }} se marcó como cerrada a las
        {{ cierreJRV[jrv].hora }}.
      </div>
      <div class="banner nivel2" v-else>
        Pulse el botón para confirmar el cierre de la {{ nombreJrv[jrv] }}.
      </div>

      <streamlined-button
        icon="CERRAR_JRV"
        back="JRV_CERRADA"
        :flip="!!cierreJRV[jrv]"
        :loading="loading"
        @click="cerrarJrv(jrv, updateSelect)"
      ></streamlined-button>
    </div>
  </jrv-pick-streamlined>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import JrvPickStreamlined from "@/components/JrvPickStreamlined.vue";
import StreamlinedButton from "@/components/StreamlinedButton.vue";
import { defineComponent } from "vue";

import geoAccion from "@/modules/geoAccion";

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "CierreJrvStreamlined",
  components: {
    JrvPickStreamlined,
    StreamlinedButton,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
      ud,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    cierreJRV() {
      return ud("cierreJRV");
    },
    incidencias(): any {
      return ud("incidencias", []);
    },
    nombreJrv(): any {
      return ud("nombreJrv");
    },
    nombreJrvDecorados(): any {
      const nj = {} as any;
      const nombres = ud("nombreJrv");
      const cierres = ud("cierreJRV");
      for (const i in nombres) {
        nj[i] = nombres[i] + " (" + (cierres[i] ? "Cerrada" : "Abierta") + ")";
      }
      return nj;
    },
  },
  methods: {

    async cerrarJrv(jrv: any, updateSelect: any) {
      const _t = this as any;

      const cerrada = ud("cierreJRV")[jrv];
      const nombre = this.nombreJrv[jrv];

      if (cerrada || this.loading) return;
      this.loading = true;

      const c = await geoAccion(
        "Va a confirmar que la " + nombre + " está cerrada. ¿Es correcto?",
        ''
/*        "No se pudo recoger su ubicación para marcar el cierre de la " +
          nombre +
          ". Puede enviar el cierre sin ubicación o intentarlo más tarde."*/
      );

      if (!c.confirmed) {
        this.loading = false;
        return;
      }

      cyanRequest("jrsl/cierreJRV/" + jrv, {
        isModal: false,
        isPost: true,
        params: c,
        needsAuth: true,
        retries: 3,
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
        }
        _t.loading = false;

        updateSelect();
      });
    },
  },
});
</script>

<style scoped>
.streamlined-button {
  margin: 3em auto 0;
  width: 75%;
  max-width: 250px;
}
</style>